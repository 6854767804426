// BOILERPLATE FOR PAGES
import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import PageWrapper from "../PageWrapper";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./updates.scss";

function Hero(){
    return (
        <>
            <div style={{ height: "200px" }} className="header-hero d-lg-flex align-items-center">

                {/* <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <div className="header-hero-content">
                                <h2 className="hero-title">Product Updates</h2>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        </>
  
    );
  
}
  
const UpdatesIndex = ({ data }) => {

    const siteTitle = data.site.siteMetadata.title
    // const posts = data.allMarkdownRemark.edges
  
    return (
  
      <PageWrapper>

        <Header component={<Hero/>} title={ siteTitle } />

        <SEO title="Product Updates - lexiQA" />
        <SEO description="Keep track of our latest product releases, new features added, major improvements and everything new in lexiQA" />

        <h1 className="hero-title text-center">Product Updates</h1>
        <section
        className="updates-container about-area pt-20 pb-100"
      >          
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="updates-content text-center">
                    {/* <h2 className="company--welcome"></h2> */}
                    <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
                </div>
              </div>
            </div>
          </div>
        </section>
               
        <Footer/>
  
      </PageWrapper>
    )
}

export default UpdatesIndex;

// ${__dirname}/src/pages/type1

export const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fileAbsolutePath: { regex: "content/updates.md/" }) {
      html
      frontmatter {
        author
        date
        title
      }
    }
  }
`

/*
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
*/